import React from 'react';

import './spinner.styles.scss';

const Spinner = () => (
  <div className="spinner-overlay">
    <div className="spinner" />
  </div>
);

export default Spinner;
